.login{
    position: relative;
    background-size: cover;
    background-position: 50% 50%;

    .powered-by {
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        bottom: 0;
        background: rgba(0,0,0,0.7);
        margin: 0;
        height: 50px;
        color: white;
    }
}

#loginForm {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    display: block;
    max-width: 400px;
    width: 80%;
    background: #ffffff;
    border-radius: 7px;
    padding: 16px;
    box-shadow: 0 3px 5px rgba(0 ,0, 0 , 0.4);
    .field {
        display: block;
        position: relative;
       label {
            display: block;
            margin-bottom: 3px;
            font-size: 14px;
            font-style: italic;
            color: #222222;
            font-weight: 500;
        }
        input {
            display: block;
            font-family: inherit;
            width: 100%;
            margin-bottom: 20px;
            border: 1px solid #d2d2d2;
            border-radius: 7px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-weight: 500;
            padding: 8px;
            transition: all .3s ease-in;
        }
    }

    button {
        padding: 0px 24px;
        height: 42px;
        line-height: 42px;
        background: #0c9eb1;
        color: #ffffff;
        border: 0;
        margin: 0 auto;
        display: block;
        border-radius: 28px;
        font-size: 24px;
        font-family: inherit;
        cursor: pointer;
    }
}