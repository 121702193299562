header {
    display: flex;
    align-items: center;

    .menu {
        flex-grow: 1;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #212121;
        color:white;
        text-decoration: none;
        width: 100%;
        label {
            font-weight: 700;
            color: #ffffff;
            font-size: 18px;
            pointer-events: none;;
        }
        svg {
            margin-left: 10px;
        }
        .icon{
            margin-left: 10px;
            width:20px;
            height:20px;
            &.lg {
                width:24px;
                height:24px;
            }
        }
        &.active {
            background: #0c9eb1;
        }
    }

    .more-menu {
        min-width: 160px;
        flex-grow: 0;
        height: 68px;
        flex-shrink: 0;
        cursor: pointer;

        ul {
            max-height:0;
            overflow:hidden;
            transition: all .3s cubic-bezier(.05, .91, .39, .96);
            li {
                border-top: 1px solid #383a3d;
                .menu {
                    height:52px;
                    label{
                        min-width: 100px;
                    }
                }
            }
            &.show {
                
                    max-height: 400px;
            
            }
        }
        
    }
}